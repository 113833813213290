@import '../../../css/variables';

.bl_lees_meer {
  background: $color-tertiary;
  padding: 6.6rem 0;

  .row {
    position: relative;
  }

  &__image_container {
    position: absolute;
    right: 15px;
    top: -20px;
    bottom: -20px;
    left: 40%;
    background: $color-primary;

    @media(max-width: 768px){
      position: static;
      width: 100%;
      aspect-ratio: 4/2;
      margin-bottom: 2rem;
    }
  }

  &__image {
    @include cover_abs;
    @include bg;
    //filter: grayscale(100%);
    //opacity: 0.5;

    @media(max-width: 768px){
      position: relative;
    }
  }

  &__titel {
    @include h1--enormous;
    color: $color-secondary;
    margin: 0;
    
    @media(max-width: 768px){
      font-size: 4rem;
    }

    @media(max-width: 480px){
      font-size: 3rem;
    }

    @include font-inherit;
    @include no-spacing;
  }

  &__text {
    margin-top: 2rem;
    max-width: 60%;
    color: $color-primary;
    font-size: 1rem;

    @media(max-width: 768px){
      max-width: 100%;
      font-size: 0.8rem;
    }

    @include font-inherit;
    @include no-spacing;
  }

  &__button {
    @include button($color-tertiary, $color-secondary);
    margin-top: 3rem;
  }
}
